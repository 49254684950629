// stylelint-disable selector-max-id

#cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 900;
  display: none;
  width: 100%;
  height: auto;
  padding: 15px 0;
  font-family: $font-family-secondary;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  background: $gray-100;
}

.cookie-bar__submit {
  margin-top: 15px;
}
