.comment {
  font-family: $font-family-secondary;
  line-height: 1.5;
  color: $gray-700;

  header {
    margin-bottom: .5rem;
  }
  main {
    > *:last-child {
      margin-bottom: 0;
    }
  }
  &-author {
    color: $gold;

    @media print {
      color: $print-gray;
    }
  }
  &-posted {
    color: $gold;

    @media print {
      color: $print-gray;
    }
  }
}
