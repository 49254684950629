// stylelint-disable selector-list-comma-newline-after

// Headings
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
  margin-bottom: 1em;
  font-family: $font-family-secondary;
  color: $blue;

  @media print {
    color: $print-black;
  }
}

h1, .h1,
h2, .h2,
h3, .h3 {
  font-weight: $font-weight-semibold;
}

h4, .h4,
h5, .h5 {
  font-weight: $font-weight-normal;
}

.h-slim {
  font-weight: $font-weight-normal;
}

// Sub-Headings
.h1-sub { font-size: $font-size-base * 1.5; }
.h2-sub { font-size: $font-size-base * 1.313; }
.h3-sub { font-size: $font-size-base; }
.h4-sub { font-size: $font-size-base * .875; }

.h1-sub,
.h2-sub,
.h3-sub,
.h4-sub {
  margin-bottom: 1em;
  color: $gold;

  @media print {
    color: $print-gray;
  }
}

//
.type-primary {
  font-family: $font-family-primary;
}

.type-secondary {
  font-family: $font-family-secondary;
  line-height: 1.5em;
}

@include media-breakpoint-down(md) {
  h1, .h1 { font-size: 36px; }
  h2, .h2 { font-size: 30px; }
  h3, .h3 { font-size: 22px; }
}

// Misc
b, strong {
  font-weight: 900;
}
