$sidebar-width: 370px;

.sidebar {
  position: relative;
  float: right;
  width: $sidebar-width;
  // margin-top: 200px;
  background-color: $body-bg;
  @include media-breakpoint-only(lg) {
    width: 280px;
  }
  @include media-breakpoint-down(md) {
    display: none;
  }
  @media print {
    display: none;
  }

  .form-control {
    padding: 12px;
    border: 0;
  }
}

.sidebar__section {
  position: relative;
  padding: 40px;
  margin-bottom: 2px;
  background-color: $gray-100;
  background-repeat: no-repeat;
  background-position: bottom right;

  > h3 > small {
    display: block;
    font-size: .875rem;
    color: $gray-700;
  }
}

.sidebar__navigation {
  padding: 40px 24px;

  .nav-link {
    color: $secondary;
    &-hover,
    &.hover,
    &:hover {
      color: $primary;
    }
    &-active,
    &.active,
    &:active {
      color: $primary;
    }
  }
}

.sidebar__login {
  font-family: $font-family-secondary;

  form {
    margin-top: 16px;
    .submit-and-forget {
      @include media-breakpoint-only(xl) {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: space-between;
      }
      * {
        margin-top: 12px;
      }
    }
  }
}

.sidebar__user {
  .user {
    position: relative;
    font-family: $font-family-secondary;
    * {
      color: $white;
      background-color: $gray-900;
      border-width: 0;
    }
    &-name {
      padding: 9px 20px;
      font-weight: $font-weight-bold;
    }
    &-dropdown__button {
      height: 48px;
      padding: 0 17px;
      margin-left: 2px;
    }
    &-dropdown__menu {
      position: absolute;
      top: 50px;
      left: 0;
      z-index: 10;
      display: none;
      width: 100%;
      padding: 5px;
      list-style: none;
      &.active {
        display: block;
      }
    }
  }
}
