// Colors

$white     : #fff;
$gray-100  : #f3f3f3;
$gray-200  : #eaeaea;
$gray-300  : #d5d5d5;
$gray-400  : #c0c0c0;
$gray-500  : #ababab;
$gray-600  : #818181;
$gray-700  : #575757;
$gray-900  : #2d2d2d;
$blue      : #003a6f;
$blue_light: #22b8fc;
$gold      : #875b38;
$orange    : #ef5c08;

$primary  : $blue;
$secondary: $gold;
$dark     : $gray-700;

$print-black: #000;
$print-gray : #555;

$theme-colors: (
  "primary"  : $primary,
  "secondary": $secondary,
  "dark"     : $dark
);

$body-bg: $white;

// Links

$link-color: $gold;
$link-hover-decoration: none;

// Fonts
$font-family-primary: "Martel";
$font-family-secondary: "Assistant";
$font-family-sans-serif: $font-family-primary, -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$font-size-base: 1rem;

$font-weight-light   : 300;
$font-weight-normal  : 400;
$font-weight-semibold: 600;
$font-weight-bold    : 700;

$font-weight-base: $font-weight-light;
$line-height-base: 1.875;

$h1-font-size: $font-size-base * 2.75; //44px
$h2-font-size: $font-size-base * 2.25; //36px
$h3-font-size: $font-size-base * 1.5;  //24px
$h4-font-size: $font-size-base * 1.125; // 18px
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;


// Grid Breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 600px,
  lg: 992px,
  xl: 1230px
);


// Grid Containers

$container-max-widths: (
  md: 570px,
  lg: 960px,
  xl: 1200px
);

$border-radius: 0;
