// stylelint-disable selector-no-qualifying-type, declaration-no-important

img {
  font-family: $font-family-secondary;
  font-size: 12px;
}

.thumbnail {
  position: absolute;
  width: 64px;
  height: 64px;
  margin-left: -100px;
  overflow: hidden;
  border-radius: 50%;

  @media print {
    display: none;
  }
}

.art_image {
  max-width: 100%;

  &_caption {
    font-family: $font-family-secondary;
    font-size: 15px;
    font-style: italic;
  }
}
div.art_image {
  width: 100% !important;
}
