// stylelint-disable declaration-no-important, selector-no-qualifying-type, selector-pseudo-element-colon-notation, font-family-no-missing-generic-family-keyword, property-no-vendor-prefix

input,
textarea,
select {
  margin-bottom: $grid-gutter-width / 2;
  font-size: 1rem;
}

input::placeholder {
  font-family: $font-family-secondary;
  font-size: 1em;
  font-weight: $font-weight-bold;
  color: $gray-700;
}

textarea {
  min-height: 140px;
}

select {
  padding: 0;
  padding-left: .75rem;
  background-image: url("../icons/angle-down.svg");
  background-repeat: no-repeat;
  background-position: center right 20px;
  background-size: 1rem;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.form-control:not(select) {
  padding: .563rem .75rem;
}

.form-group {
  label {
    margin-bottom: 0;
    font-family: $font-family-secondary;
    font-size: 1rem;
    color: $gray-700;
  }
}

.input-group {
  &-prepend {
    margin-right: 2px;
  }
  &-text {
    background-color: $white;
    border: 0;
  }
  &-text::before {
    width: 24px !important;
  }
  &-text,
  & > .form-control {
    margin-bottom: 8px;
  }
}

input[type="checkbox"] {
  opacity: 0;
  + label {
    position: relative;
    padding-left: 10px;
    font-family: $font-family-secondary;
    &:before {
      position: absolute;
      top: -1px;
      left: -22px;
      display: block;
      width: 20px;
      content: "\00a0";
      background-image: url("../icons/rectangle.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &:checked + label:after {
    position: absolute;
    top: 1px;
    left: -19px;
    display: block;
    font-family: FontAwesome;
    font-size: 14px;
    color: $gray-500;
    content: "";
  }
}
