// stylelint-disable font-family-no-missing-generic-family-keyword

// Font Awesome 5

.btn {
  &.fas,
  &.far,
  &.fal {
    &::before {
      padding-right: 9px;
      font-family: "Font Awesome\ 5 Free", "Font Awesome\ 5 Brands";
    }
  }
}

// Icons

%icon {
  display: inline-block;
  float: left;
  content: "\00a0";
  background-repeat: no-repeat;
  background-position: center;
  &-before-text {
    @extend %icon;
    float: left;
    margin-right: 10px;
  }
  &-after-text {
    @extend %icon;
    float: right;
    margin-left: 10px;
  }
}

.icon {
  &-mobile-menu::before {
    @extend %icon;
    width: 23px;
    background-image: url("../icons/mobile-menu.svg");
  }
  &-login::before {
    @extend %icon;
    width: 12px;
    background-image: url("../icons/login.svg");
  }
  &-key::before {
    @extend %icon;
    width: 16px;
    background-image: url("../icons/key.svg");
  }
  &-dots::before {
    @extend %icon;
    width: 13px;
    background-image: url("../icons/dots.svg");
  }
  &-arrow-next::before {
    @extend %icon;
    width: 8px;
    background-image: url("../icons/arrow-next.svg");
  }
  &-arrow-prev::before {
    @extend %icon;
    width: 9px;
    background-image: url("../icons/arrow-prev.svg");
  }

  &-share-before::before {
    @extend %icon-before-text;
    width: 14px;
    background-image: url("../icons/share.svg");
  }
  &-print-before::before {
    @extend %icon-before-text;
    width: 15px;
    background-image: url("../icons/print.svg");
  }
  &-search-before::before {
    @extend %icon-before-text;
    width: 15px;
    background-image: url("../icons/search.svg");
  }
  &-download-before::before {
    @extend %icon-before-text;
    width: 23px;
    margin-right: 30px;
    background-image: url("../icons/download.svg");
  }
  &-arrow-right-before::before {
    @extend %icon-before-text;
    width: 16px;
    margin-right: 5px;
    background-image: url("../icons/arrow-right.svg");
  }
  &-edit-before::before {
    @extend %icon-before-text;
    width: 14px;
    background-image: url("../icons/edit.svg");
  }
}
