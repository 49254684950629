.site-section {
  ul:not(.pagination) {
    ul {
      padding-left: 22px;
    }
  }
  .form-check + ul:not(.pagination) {
    padding-left: 32px;
  }
}

.no-list-style {
  list-style: none;
}
