// stylelint-disable declaration-no-important

// Main Content Area
.site-content {
  @extend .clearfix;
  position: relative;

  .page-start & {
    padding-top: 200px;
    @include media-breakpoint-only(lg) {
      padding-top: 110px;
    }
    @include media-breakpoint-down(md) {
      padding-top: 50px;
    }
  }

  .background {
    width: 100%;
    @include media-breakpoint-down(md) {
      display: none;
    }
    @media print {
      display: none;
    }
  }
}

// Content sections
.site-section-wide {
  @extend .container;

  position: relative;
  float: left;
  padding-right: 0;
  padding-left: 0;

  .sidebar + & {
    margin-top: -80px;
    @include media-breakpoint-down(md) {
      margin-top: 50px;
    }
    @media print {
      margin-top: 0;
    }
  }

  .sidebar + &,
  .background + & {
    padding-top: 60px;
  }
}

.site-section {
  @extend .site-section-wide;

  max-width: 800px;
  padding-right: 130px;
  padding-left: 100px;
  background-color: $body-bg;
  @include media-breakpoint-only(lg) {
    width: 650px;
    padding: 60px 50px 0 30px;
  }
  @include media-breakpoint-down(md) {
    padding: 0;
  }
}

.site-section__inner {
  margin-bottom: 4rem;
  &.spacer-below,
  &.spacer-below-thin {
    margin-bottom: 0;
  }
}
