// stylelint-disable

/* assistant-regular - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/assistant-v2-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Assistant'), local('Assistant-Regular'),
       url('../fonts/assistant-v2-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/assistant-v2-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/assistant-v2-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/assistant-v2-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/assistant-v2-latin-regular.svg#Assistant') format('svg'); /* Legacy iOS */
}
/* assistant-600 - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/assistant-v2-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Assistant SemiBold'), local('Assistant-SemiBold'),
       url('../fonts/assistant-v2-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/assistant-v2-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/assistant-v2-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/assistant-v2-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/assistant-v2-latin-600.svg#Assistant') format('svg'); /* Legacy iOS */
}
/* assistant-700 - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/assistant-v2-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Assistant Bold'), local('Assistant-Bold'),
       url('../fonts/assistant-v2-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/assistant-v2-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/assistant-v2-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/assistant-v2-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/assistant-v2-latin-700.svg#Assistant') format('svg'); /* Legacy iOS */
}


/* martel-regular - latin */
@font-face {
  font-family: 'Martel';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/martel-v2-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Martel'), local('Martel-Regular'),
       url('../fonts/martel-v2-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/martel-v2-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/martel-v2-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/martel-v2-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/martel-v2-latin-regular.svg#Martel') format('svg'); /* Legacy iOS */
}
/* martel-600 - latin */
@font-face {
  font-family: 'Martel';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/martel-v2-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Martel DemiBold'), local('Martel-DemiBold'),
       url('../fonts/martel-v2-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/martel-v2-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/martel-v2-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/martel-v2-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/martel-v2-latin-600.svg#Martel') format('svg'); /* Legacy iOS */
}
/* martel-300 - latin */
@font-face {
  font-family: 'Martel';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/martel-v2-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Martel Light'), local('Martel-Light'),
       url('../fonts/martel-v2-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/martel-v2-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/martel-v2-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/martel-v2-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/martel-v2-latin-300.svg#Martel') format('svg'); /* Legacy iOS */
}
/* martel-900 - latin */
@font-face {
  font-family: 'Martel';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/martel-v2-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Martel Heavy'), local('Martel-Heavy'),
       url('../fonts/martel-v2-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/martel-v2-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/martel-v2-latin-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/martel-v2-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/martel-v2-latin-900.svg#Martel') format('svg'); /* Legacy iOS */
}