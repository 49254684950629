// stylelint-disable selector-list-comma-newline-after, selector-max-class, selector-max-compound-selectors

.site-header {
  .search-and-share {
    line-height: 60px;
    border-bottom: 2px solid $gray-200;
    @include media-breakpoint-down(md) {
      display: none;
    }
    @media print {
      display: none;
    }

    .search {
      width: 44px;
      padding: 0;
      margin: 0;
      border: 0;
      transition: width 2s;
      &:focus {
        width: 200px;
        border-bottom: 2px solid $gray-300;
        box-shadow: none;
      }
    }

    .btn-search {
      padding: 0;
      font-family: $font-family-secondary;
      font-weight: $font-weight-bold;
      color: $gray-500;
    }
  }
  .logo-and-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      padding: 20px 0;
    }
    .navigation {
      margin-right: - ($grid-gutter-width / 2);
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
    @media print {
      display: none;
    }
  }
}

.mobile-navigation {
  position: relative;
  font-family: $font-family-secondary;
  font-size: 16px;
  border-bottom: 2px solid $gray-200;

  @media print {
    display: none;
  }

  .logo img {
    max-height: 40px;
  }
  .header-menu__button {
    color: $white;
    border: 0;
  }
  &__wrap {
    position: absolute;
    left: 0;
    z-index: 10;
    display: none;
    width: 100vw;
    padding-top: 30px;
    padding-bottom: 30px;
    color: $white;
    background-color: $blue;
    h1, h2, h3,
    h4, h5, h6 {
      color: $white;
    }
    a {
      color: $white;
      &:hover {
        color: $white;
      }
    }
    .nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .nav {
        display: none;
        padding: 0;
      }
    }
    .nav-item {
      position: relative;
      width: 100%;
      font-size: $h3-font-size;
      .nav-item {
        padding-left: 30px;
        font-size: $h4-font-size;
      }
      .nav-toggle {
        position: absolute;
        top: 20px;
        right: -20px;
        width: 50px;
        text-align: center;
        &::before {
          display: inline-block;
          content: "+";
        }
        &.active::before {
          content: "-";
        }
        &.active + {
          content: "-";
          .nav {
            display: initial;
          }
        }
      }
    }
    .nav-link {
      padding: 20px 0;
      font-weight: $font-weight-semibold;
      color: $white;
    }
    .search {
      padding-top: 1rem;
      margin-top: 1rem;
      border-top: 2px solid $gray-200;
    }
    .login {
      padding-top: 1rem;
      margin-top: 2rem;
      border-top: 2px solid $gray-200;
      h3 {
        margin-bottom: .5rem;
      }
    }
    .user {
      padding-top: 1rem;
      margin-top: 1rem;
      font-family: $font-family-secondary;
      border-top: 2px solid $gray-200;
      &-name {
        text-align: right;
      }
      .nav {
        .nav-link {
          padding: .5rem 0;
        }
      }
    }
  }
  &.active .mobile-navigation__wrap {
    display: block;
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
}
