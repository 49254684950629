.pagination {
  flex-wrap: wrap;
  font-family: $font-family-secondary;
}

.pagination .page-item {
  width: 40px;
  height: 40px;
  margin-top: 8px;
  margin-right: 8px;
}

.pagination .page-link {
  padding: 8px 0;
  margin-left: 0;
  color: $primary;
  text-align: center;
  border-color: $primary;
  border-width: 2px;

  @media print {
    color: $print-black;
    border-color: $print-black;
  }
}
.pagination .page-link-hover,
.pagination .page-link:hover {
  color: $white;
  background-color: $primary;

  @media print {
    background-color: $print-black;
  }
}

.pagination .page-item.active .page-link {
  @media print {
    background-color: $print-black;
    border-color: $print-black;
  }
}
