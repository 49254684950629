.section-intern {
  &-info {
    main {
      padding-bottom: 15px;
      font-family: $font-family-secondary;
      color: $gray-700;
      h6 {
        margin: 0;
        font-weight: $font-weight-bold;
      }
    }
  }
}
