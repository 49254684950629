// stylelint-disable selector-max-type

table,
.table {
  font-family: $font-family-secondary;
  td {
    padding: $grid-gutter-width / 2;
    padding-left: 0;
  }
  td:last-of-type {
    padding-right: 0;
  }
  thead {
    td {
      font-weight: $font-weight-bold;
      color: $gray-700;
      border-top: 0;
    }
  }
  tbody {
    a {
      font-weight: $font-weight-normal;
      color: $blue;
    }
    .meta {
      display: block;
      color: $gold;

      @media print {
        color: $print-gray;
      }
    }
    tr:last-of-type td {
      border-bottom: 1px solid $gray-300;
    }
  }
}
