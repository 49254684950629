.nav {
  font-family: $font-family-secondary;
  font-size: 1rem;
  font-weight: $font-weight-bold;
  line-height: 1.5;
  .nav {
    padding-left: $grid-gutter-width;
  }
}

.nav-link {
  color: $primary;
}
.nav-link-hover,
.nav-link.hover,
.nav-link:hover {
  color: $secondary;
}
.nav-link-active,
.nav-link.active,
.nav-link:active {
  color: $secondary;
}
