%spacer {
  position: absolute;
  display: block;
  width: 100%;
  height: 0;
  content: "\00a0";
  border-top: 2px solid $gray-200;
}

.spacer {
  &-below {
    position: relative;
    &::after {
      @extend %spacer;
      position: relative;
      bottom: 0;
      left: 0;
      margin-top: 4rem;
      margin-bottom: 4rem;
    }
  }
  &-below-thin {
    position: relative;
    &::after {
      @extend %spacer;
      position: relative;
      bottom: 0;
      left: 0;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
}

.spacer-between + .spacer-between {
  position: relative;
  margin-top: 120px;
  &::before {
    @extend %spacer;
    top: 0;
    left: 0;
    margin-top: -60px;
  }
}
.spacer-between-thin + .spacer-between-thin {
  position: relative;
  margin-top: 60px;
  &::before {
    @extend %spacer;
    top: 0;
    left: 0;
    margin-top: -30px;
  }
}
