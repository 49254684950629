.site-footer {
  padding: 30px 0 120px;
  margin-top: 120px;
  font-family: $font-family-secondary;
  font-size: .875rem; // 14px
  line-height: 1.5; // 21px
  background-color: $gray-100;

  @media print {
    display: none;
  }

  .contact {
    @include media-breakpoint-up(xl) {
      width: $sidebar-width + $grid-gutter-width;
    }
  }
}

.footer__content {
  margin-bottom: 20px;
}

.footer__section {
  padding-top: 30px;
  padding-bottom: 10px;
}

.footer__section__headline {
  padding-bottom: 30px;
  margin-bottom: 40px;
  border-bottom: 2px solid $gray-200;
}

.footer__list {
  padding: 0;
  list-style: none;
}

.footer__list__heading {
  margin-bottom: 2px;
  font-size: 1em;
  text-transform: uppercase;
}

.footer__list a,
.footer__list a:hover {
  font-weight: $font-weight-normal;
  color: $gray-600;
  text-decoration: none;
}

.footer__copyright {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  color: $gray-600;
}
