// stylelint-disable selector-max-type, declaration-no-important, selector-max-id

.caltab {
  width: 100%;
  margin-bottom: $grid-gutter-width;
  font-family: $font-family-secondary;
  font-weight: $font-weight-normal;
  color: $blue;
  text-align: center;
  table-layout: fixed;
  background-color: $gray-100;

  @media print {
    width: 50%;
  }

  thead {
    background-color: $gray-200;

    th {
      height: 48px;
      // border: 0;
    }
    a {
      color: $blue;
    }
    #caltab-header {
      font-size: 1.125rem;
      font-weight: $font-weight-normal;
    }
  }
  tbody {
    tr:last-of-type td {
      border-bottom: 0;
    }
    td {
      height: 70px;
      padding: 1px 1px 1px 0;
      @include media-breakpoint-down(sm) {
        height: 12vw;
      }
    }
    .days-of-week {
      color: $gray-900;
    }
    a {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: $white !important;
      text-decoration: none;
      background-color: $blue;

      > span {
        position: absolute;
        top: 0;
        left: 100%;
        z-index: 10;
        display: none;
        width: auto !important;
        min-width: 200px;
        padding: 5px;
        margin-left: 2px;
        font-weight: $font-weight-normal;
        color: $gray-900;
        background-color: $white;
        border: 1px solid $gray-900;

        &::before {
          position: absolute;
          top: -1px;
          left: -3px;
          width: 2px;
          height: 100%;
          content: "";
        }
      }
      &:hover > span {
        display: block;
      }
    }
  }
}
