// stylelint-disable selector-list-comma-newline-after

a {
  font-weight: $font-weight-bold;

  @media print {
    color: $print-black;
  }
}

h1 > a, h2 > a, h3 > a,
h4 > a, h5 > a, h6 > a {
  font-weight: inherit;
  color: inherit;
}

h1 > a:hover, h2 > a:hover, h3 > a:hover,
h4 > a:hover, h5 > a:hover, h6 > a:hover {
  color: inherit;
  text-decoration: none;
}

.btn {
  padding: .5em 1em;
  font-family: $font-family-secondary;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  border-width: 2px;
}

.btn-slim {
  padding: 3px 16px;
}

.btn-primary {
  @media print {
    background-color: $print-black;
  }
}
.btn-primary:hover,
.btn-primary-hover,
.btn-primary--hover {
  color: $primary;
  background-color: transparent;
  border: 2px solid $primary;

  @media print {
    color: $print-black;
    border-color: $print-black;
  }
}

.btn-secondary {
  color: $primary;
  background-color: transparent;
  border-color: $primary;

  @media print {
    color: $print-black;
    border-color: $print-black;
  }
}
.btn-secondary:hover,
.btn-secondary-hover,
.btn-secondary--hover {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}
.btn-secondary:focus,
.btn-secondary-focus,
.btn-secondary--focus {
  box-shadow: 0 0 0 .2rem rgba(0, 58, 111, .25);
}

.btn-tertiary {
  color: $blue_light;
  background-color: transparent;
  border-color: $blue_light;
}
.btn-tertiary:hover,
.btn-tertiary-hover,
.btn-tertiary--hover {
  color: $white;
  background-color: $blue_light;
  border-color: $blue_light;
}

.btn-danger {
  color: $orange;
  background-color: transparent;
  border-color: $orange;
}
.btn-danger:hover,
.btn-danger_hover {
  color: $white;
  background-color: $orange;
  border-color: $orange;
}

.btn-link,
.btn-link:hover,
.btn-link:focus {
  font-weight: $font-weight-bold;
  color: $gold;
  text-decoration: none;

  @media print {
    color: $print-gray;
  }
}
.btn-link:focus {
  outline-width: thin;
  outline-style: dotted;
  outline-color: $secondary;
}

.btn-negative {
  color: $white;
  background-color: transparent;
  border-color: $white;
}
