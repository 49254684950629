// stylelint-disable declaration-no-important

.section-sponsorship {
  @media print {
    display: none;
  }

  .site-section__inner {
    padding: 60px 100px;
    font-family: $font-family-secondary;
    line-height: 1.5;
    background-color: $gray-100;
    @include media-breakpoint-down(md) {
      padding: 30px 50px;
    }
  }

  .sponsorship-list {
    overflow: hidden;
  }

  .sponsorship-item {
    transition: transform 2s;
  }

  &__picture {
    height: 160px;
    max-height: 160px;
    margin-bottom: 1.5em;
    overflow: hidden;
    background-position: center;
    background-size: cover;
  }

  .pagination {
    %button {
      position: absolute;
      top: 50%;
      width: 50px;
      height: 50px;
      color: $white;
      background-color: $blue;
      border-radius: 50%;
      :hover {
        color: $white;
        background-color: $blue;
      }
    }
    .button-next {
      @extend %button;
      right: -25px;
      &::before {
        float: none;
        margin-left: 1px;
      }
    }
    .button-previous {
      @extend %button;
      left: -25px;
      &::before {
        float: none;
        margin-left: -1px;
      }
    }
  }
}
